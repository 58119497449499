import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import tiktok from "../assets/ME.jpg";
import sound from "../assets/BAND.jpg";
import gram from "../assets/WORK.jpg";
import signature from "../assets/qsig.png";

import * as styles from './HomePage.module.scss';
import SignupForm from "../components/SignupForm";

const IndexPage = (props) => {

  const { indexImage } = useStaticQuery(
    graphql`
      query {
        indexImage: file(relativePath: {eq: "salt.jpg"}) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: {quality: 70}
            )
          }
        }
      }
    `
  )

const pluginImage = getImage(indexImage)

  return (

    <div className={styles.HomePage}>
    <BgImage
        image={pluginImage}
        className={styles.Background}
        >
      <div className={styles.BckOverlay}> 
      <div>       
        <h1>Quinn Carson</h1>
      </div>
        <section className={styles.VideoContainer}>
          <iframe 
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/1zan3QcEk40" 
              title="BONE FX 3" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
        </section>
        <section className={styles.SignupContainer}>
          <SignupForm />
        </section>
        <section className={styles.CardContainer}>
          <div className={styles.Card}>
            <a href="https://vm.tiktok.com/ZMeyUNysh/" 
                  className={styles.ContentLink}
                  target="_blank"
                  rel="noreferrer"
                  >
                <img src={tiktok} alt="TikTok"/>
                <h4 className={styles.CardHeading}>Me</h4>
            </a>
          </div>
          <div className={styles.Card}>
            <a href="https://soundcloud.app.goo.gl/jGYfTARkzKsrcQ7r6" 
                  className={styles.ContentLink}
                  target="_blank"
                  rel="noreferrer"
                  >
                <img src={sound} alt="SoundCloud"/>
                <h4 className={styles.CardHeading}>My Band</h4>
            </a>
          </div>
          <div className={styles.Card}>
            <a href="https://instagram.com/quinncarson" 
                  className={styles.ContentLink}
                  target="_blank"
                  rel="noreferrer"
                  >
                <img src={gram} alt="Instagram"/>
                <h4 className={styles.CardHeading}>My Work</h4>
            </a>
          </div>
        </section>
        <div className={styles.Signature}>
          <img src={signature} alt="Signature"/>
        </div>
      </div>
      </BgImage>
    </div>
  );
};

export default IndexPage;
