import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import * as styles from './SignupForm.module.scss';

export default class SignupForm extends React.Component {

    constructor() {
        super()
        this.state = { 
            firstName: "",
            lastName: "",
            email: "",
            result: null
        }
    }

    getInitialFormState = () => {
        return {
            firstName: "",
            lastName: "",
            email: "",
            result: {}
        }
    };

    _handleSubmit = async e => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email, {
            FNAME: this.state.firstName,
            LNAME: this.state.lastName,
        });
        this.setState({ result: result.result });
    }

    handleChange = event => {
        this.setState({ 
            [`${event.target.name}`]: event.target.value,
        })
    }

    render () {
        return this.state.result === "success" ? (
            <div className={styles.SuccessContainer}>
                <h4 className={styles.SuccessMsg}>Thank You for Subscribing! Check Out Our Latest Track.</h4>
                <a href="https://www.jake-knox.com/quinn"
                    target="_blank"
                    rel="noreferrer">
                    <button className={styles.TrackButton}>New Track</button>
                </a>
            </div>
        ) : this.state.result === "error" ? (
            <div className={styles.ErrorMsg}>ERROR</div>
        ) : (
                <form 
                    onSubmit={this._handleSubmit}
                    className={styles.SignupForm}>
                    <h4 className={styles.FormHeading}>Signup and Receive Access to "BONE FX 3"</h4>
                    <TextField 
                        id="outlined-email-input"
                        label="Email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        variant="outlined"
                        onChange={this.handleChange}
                    />
                    <br />
                    <TextField 
                        id="outlined-name-input"
                        label="First Name"
                        type="text"
                        name="firstName"
                        autoComplete="firstName"
                        variant="outlined"
                        onChange={this.handleChange}
                    />
                    <br />
                    <TextField 
                        id="outlined-name-input"
                        label="Last Name"
                        type="text"
                        name="lastName"
                        autoComplete="lastName"
                        variant="outlined"
                        onChange={this.handleChange}
                    />
                    <br />
                        <Button 
                            variant="contained"
                            color="primary"
                            label="Submit"
                            type="submit"
                        >
                            <Typography variant="button">Submit</Typography>
                        </Button>
                </form>
        )
    }
}